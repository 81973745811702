.flex {
    height: 100%;
    display: flex;

    /*border: 1px solid red;*/
}
.inline-flex {
    display: inline-flex;
}
.flex-col {
    flex-direction: column;

    /*border: 1px solid blue;*/
}
.flex-row {
    flex-direction: row;

    /*border: 1px solid green;*/
}
.flex-jcc {
    justify-content: center;
}
.flex-aic {
    align-items: center;
}
.flex-wrap {
    flex-wrap: wrap;
}

.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-25 {
    margin-top: 25px;
}

.mr-5 {
    margin-right: 5px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-25 {
    margin-right: 25px;
}

.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-25 {
    margin-bottom: 25px;
}

.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-25 {
    margin-left: 25px;
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}